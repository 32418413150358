.basic-form .input-form {
  margin-bottom: 0rem;
  margin-top: 1.5rem;
}

.image-picker img {
  height: 350px;
  object-fit: cover;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

.article-picker {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.article-picker img {
  width: 31%;
  margin: 0.5rem;
  object-fit: cover;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

.more-button {
  color: #3c4b64;
}

/*  Pagination effect inside of the table in the users page */
.MuiTablePagination-displayedRows,
.MuiInputBase-root,
.MuiTablePagination-selectLabel {
  margin: 0 !important;
  padding: 0 !important;
}

.more-info-field {
  font-size: 1.1rem;
}

.more-info-field span {
  font-size: 1.3rem;
  font-weight: 600;
  margin-right: 1rem;
}
