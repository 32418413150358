.company-admin-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-admin-logo img {
  width: 125px;
}

.logout-admin-button {
  width: 100%;
  height: 100%;
  color: #f2f2f2;
}

.logout-admin-button:hover {
  color: #9f9f9f;
}

.logo-header {
  width: 125px;
}

.header-logo-container {
  /* display: flex !important; */
  justify-content: end !important;
}
